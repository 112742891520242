<template>
  <b-sidebar
    id="form-sidebar"
    ref="sideBar"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title=""
    right
    shadow
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #header="{hide}">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h5 class="mb-0">
          Cadastro de Campo Dinâmico
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer float-right"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
    </template>
    <template #footer="{hide}">
      <div class="p-1">
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>

    <template #default="{ hide }">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.locator"
                :disabled="true"
                :readonly="true"
                label="Localizador"
                placeholder=""
                name="locate"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.name"

                rules="required"
                label="Nome"
                placeholder=""
                name="name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-vue-select
                v-model="model.type"
                rules="required"
                label="Tipo do Campo"
                :options="dynamicFieldsTypes" name="type"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-vue-select
                v-model="model.context"
                rules="required"
                label="Contexto"
                :options="dynamicFieldsContext" name="context"
              />
            </b-col>
          </b-row>
          <!--          <b-row>-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--            >-->
          <!--              <i2-form-input-->
          <!--                v-model="model.properties"-->
          <!--                label="Propriedades"-->
          <!--                placeholder=""-->
          <!--                name="properties"-->
          <!--              />-->
          <!--            </b-col>-->
          <!--          </b-row>-->

        </b-form>

      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import dynamicFieldsTypes from '@/variables/DynamicFieldsTypes'
import dynamicFieldsContext from '@/variables/DynamicFieldsContext'

export default {
  name: 'DynamicFieldForm',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      dynamicFieldsTypes,
      dynamicFieldsContext,
      model: {
        uuid: null,
        name: '',
        type: '',
        context: '',
        properties: '',

      },
    }
  },
  watch: {
    uuid(uuid) {
      if (uuid) {
        this.getData(uuid)
      }
    },
  },
  async mounted() {

  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          for (const field in data) {
            data[field] = data[field]
            if (data[field] != null && data[field].value) {
              data[field] = data[field].value
            }
          }

          if (this.model.uuid) {
            response = await this.$http.put(`dynamic-fields/${this.model.uuid}`, data)
          } else {
            response = await this.$http.post('dynamic-fields', data)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$notify.success('Campo dinâmico salvo com sucesso.')
          this.$emit('onSave', true)
          this.resetModel()
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.resetModel()
      this.$refs.refFormObserver.reset()
    },
    async getData(uuid) {
      const response = await this.$http.get(`dynamic-fields/${uuid}`)
      this.model = response
      this.model.type = this.dynamicFieldsTypes.find(dfType => dfType.value === response.type)
      this.model.context = this.dynamicFieldsContext.find(dfContext => dfContext.value === response.context)
    },
    resetModel() {
      this.model = {
        uuid: null,
        name: '',
        type: '',
        context: '',
        properties: '',
        locator: '',

      }
      this.$refs.refFormObserver.reset()
    },
  },
}
</script>

<style scoped>

</style>
