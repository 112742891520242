const dynamicFieldsTypes = [
  {
    value: 'text',
    text: 'Texto',
  },
  {
    value: 'date',
    text: 'Data',
  },
  {
    value: 'number',
    text: 'Número',
  },
]
export default dynamicFieldsTypes
